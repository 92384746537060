// noinspection TypeScriptCheckImport
import Glide from '@glidejs/glide'
import '@glidejs/glide/dist/css/glide.core.min.css'

// import '@glidejs/glide/dist/css/glide.theme.min.css'

class BestsellerSlider {
    constructor() {
        this.init()
    }

    init() {
        const sliders = document.querySelectorAll('.glide')
        for (let i = 0; i < sliders.length; i++) {
            const glide = new Glide(sliders[i], {
                type: 'carousel',
                autoplay: 5000,
                animationDuration: 2000,
                hoverpause: true
            })

            glide.mount()
        }

    }
}

export {BestsellerSlider}
