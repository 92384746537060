import {DecorateContentTable} from '@/js/frontendBase/decorateStdContent'
import {ResponsiveGoogleMap} from "@/js/frontendBase/googleMaps"
import {ElementToggler} from "@/js/frontendBase/varienElementToggler"
import {TabSwitcher} from "@/js/frontendBase/reviewTabSwitcher"
import {MenuManager, SkipLinkManager} from "@/js/frontendBase/menuManager"
import {BestsellerSlider} from "@/js/frontendBase/bestsellerSlider"

declare const bp: { medium: any }

document.addEventListener('DOMContentLoaded', function () {
    new DecorateContentTable()
    new ResponsiveGoogleMap()
    new ElementToggler(bp.medium)
    new TabSwitcher()
    new MenuManager()
    new SkipLinkManager()
    new BestsellerSlider()
})
