class TabSwitcher {
    constructor() {
        this.startReadReviewsObserver()
    }

    startReadReviewsObserver() {
        document.querySelectorAll('a[href*="#tab"]').forEach((element) => {
            element.addEventListener('click', (event) => {
                event.preventDefault()
                const hash = element.getAttribute('href')
                if (!hash) {
                    return
                }
                const tabindex = hash.slice(hash.lastIndexOf('-') + 1)
                this.showTab(tabindex)
                this.smoothScrollTo('#collateral-tabs')
            })
        })
    }

    showTab(tabindex: string | number) {
        if (!document) {
            return
        }
        const toggleTabs = document.querySelector('.toggle-tabs')
        const collateralTabs = document.querySelector('.collateral-tabs')
        if (!toggleTabs || !collateralTabs) {
            return
        }
        const tabs = Array.from(toggleTabs.children)
        const contentTitles = Array.from((collateralTabs).querySelectorAll('dt'))
        const contents = Array.from((collateralTabs).querySelectorAll('dd'))
        tabs.forEach((tab, index) => {
            tab.classList.remove('current')
            if (index == tabindex) {
                tab.classList.add('current')
            }
        })
        contentTitles.forEach((title, index) => {
            title.classList.remove('current')
            if (index == tabindex) {
                title.classList.add('current')
            }
        })
        contents.forEach((content, index) => {
            content.classList.remove('current')
            if (index == tabindex) {
                content.classList.add('current')
            }
        })
    }

    smoothScrollTo(elementToScrollTo: string) {
        const element: HTMLElement | null = document.querySelector(elementToScrollTo)
        if (!element) {
            return
        }
        window.scrollTo({
            top: element.offsetTop,
            behavior: 'smooth'
        })
    }
}

export {TabSwitcher}
