class ResponsiveGoogleMap {
    constructor() {
        const toggleTabs = document.querySelectorAll('.toggle-tabs')
        toggleTabs.forEach((wrapper) => {
            const tabs = wrapper.children
            Array.from(tabs).forEach((tab) => {
                const tabDescription = tab.querySelector('span:first-child')
                if (!tabDescription) {
                    return
                }
                const innerHtml = tabDescription.innerHTML
                if (innerHtml === 'Details') {
                    tab.addEventListener('click', () => {
                        const googlemap = document.querySelector('.googlemap')
                        const iframe = googlemap ? googlemap.querySelector('iframe') : null
                        if (!iframe || !iframe.classList.contains('clicked')) {
                            iframe?.classList.add('clicked')
                        }
                    })
                }
            })
        })
    }
}

export {ResponsiveGoogleMap}
