class DecorateContentTable {
    constructor() {
        this.addTableStyle()
        this.addHeadingMargins()
    }

    addTableStyle() {
        const tables = document.querySelectorAll('.std > table')
        tables.forEach((table) => {
            table.classList.add('zebra-table')
        })
    }

    addHeadingMargins() {
        const headings = document.querySelectorAll('.std > h2:not(:first-child)')
        headings.forEach((heading) => {
            heading.classList.add('margin-top-medium')
        })
    }
}

export {DecorateContentTable}
