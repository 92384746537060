/* eslint-disable @typescript-eslint/no-this-alias */

// noinspection JSUnusedGlobalSymbols
class PointerManager {
    MOUSE_POINTER_TYPE = 'mouse'
    TOUCH_POINTER_TYPE = 'touch'
    POINTER_EVENT_TIMEOUT_MS = 500
    standardTouch = false
    touchDetectionEvent = "touchstart"
    lastTouchType: null | string = null
    pointerTimeout: null | NodeJS.Timeout = null
    pointerEventLock = false

    constructor() {
        this.init()
    }

    getPointerEventsSupported() {
        return this.standardTouch
    }

    getPointerEventsInputTypes() {
        return {}
    }

    getPointer() {
        return this.MOUSE_POINTER_TYPE
    }

    setPointerEventLock() {
        this.pointerEventLock = true
    }

    clearPointerEventLock() {
        this.pointerEventLock = false
    }

    setPointerEventLockTimeout() {
        const that = this

        if (this.pointerTimeout) {
            clearTimeout(this.pointerTimeout)
        }

        this.setPointerEventLock()
        this.pointerTimeout = setTimeout(function () {
            that.clearPointerEventLock()
        }, this.POINTER_EVENT_TIMEOUT_MS)
    }

    triggerMouseEvent(originalEvent: BeforeUnloadEvent) {
        if (this.lastTouchType === this.MOUSE_POINTER_TYPE) {
            return
        }

        this.lastTouchType = this.MOUSE_POINTER_TYPE
        window.dispatchEvent(new CustomEvent('mouse-detected', {detail: originalEvent}))
    }

    triggerTouchEvent(originalEvent: BeforeUnloadEvent) {
        if (this.lastTouchType === this.TOUCH_POINTER_TYPE) {
            return
        }
        this.lastTouchType = this.TOUCH_POINTER_TYPE
        window.dispatchEvent(new CustomEvent('touch-detected', {detail: originalEvent}))
    }

    wirePointerDetection() {
        const that = this
        if (this.standardTouch) {
            window.addEventListener(this.touchDetectionEvent, function (e) {
                switch ((e as PointerEvent).pointerType) {
                    case "mouse":
                        that.triggerMouseEvent(e)
                        break
                    case "touch":
                    case "pen":
                        that.triggerTouchEvent(e)
                        break
                }
            })
        } else {
            window.addEventListener(this.touchDetectionEvent, function (e) {
                if (that.pointerEventLock) {
                    return
                }

                that.setPointerEventLockTimeout()
                that.triggerTouchEvent(e)
            })

            document.addEventListener('mouseover', function (e) {
                if (that.pointerEventLock) {
                    return
                }

                that.setPointerEventLockTimeout()
                that.triggerMouseEvent(e)
            })
        }
    }

    init() {
        this.wirePointerDetection()
    }
}

class MenuManager {
    // These variables are used to detect incorrect touch / mouse event order
    mouseEnterEventObserved = false
    touchEventOrderIncorrect = false
    cancelNextTouch = false
    private pointerManager: PointerManager

    constructor() {
        this.pointerManager = new PointerManager()
        this.wirePointerEvents()
        this.preventMenuSpill()
        window.addEventListener('delayed-resize', this.preventMenuSpill)
    }

    TouchScroll = {

        TOUCH_SCROLL_THRESHOLD: 20,

        touchStartPosition: null as number | null,

        reset: function () {
            this.touchStartPosition = window.scrollY || document.documentElement.scrollTop
        },


        shouldCancelTouch: function () {
            if (this.touchStartPosition == null) {
                return false
            }

            const scroll = (window.scrollY || document.documentElement.scrollTop) - this.touchStartPosition
            return Math.abs(scroll) > this.TOUCH_SCROLL_THRESHOLD
        }
    }

    useSmallScreenBehavior() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return window.matchMedia('screen and (max-width:' + bp.medium + 'px)').matches
    }

    toggleMenuVisibility(target: Element) {
        const li = target.closest('li')
        if (!this.useSmallScreenBehavior() && li) {
            const liParentNode = li.parentNode
            if (!liParentNode) {
                return
            }
            // remove menu-active from siblings and children of siblings
            Array.from(liParentNode.children as HTMLCollection).forEach(function (sibling: Element) {
                if (sibling !== li) {
                    sibling.classList.remove('menu-active')
                    Array.from(sibling.getElementsByTagName('li')).forEach(function (child) {
                        child.classList.remove('menu-active')
                    })
                }
            })

            //remove menu-active from children
            Array.from(li.getElementsByTagName('li') as HTMLCollection).forEach(function (child: Element) {
                child.classList.remove('menu-active')
            })
            li.classList.toggle('menu-active')
            return
        }

        //toggle current item's active state
        if (!li) {
            return
        }
        li.classList.toggle('menu-active')
    }

    wirePointerEvents() {
        const that = this
        const pointerTarget = document.querySelectorAll('#nav a.has-children')
        const hoverTarget = document.querySelectorAll('#nav li')
        this.pointerManager = new PointerManager()

        if (this.pointerManager.getPointerEventsSupported()) {
            // noinspection JSUnresolvedReference
            const enterEvent = window.PointerEvent ? 'pointerenter' : 'mouseenter'            // noinspection JSUnresolvedReference
            const leaveEvent = window.PointerEvent ? 'pointerleave' : 'mouseleave'
            // noinspection JSUnresolvedReference
            const fullPointerSupport = !!window.PointerEvent

            hoverTarget.forEach(element => element.addEventListener(enterEvent, function (e) {
                if ((e as PointerEvent) === undefined || (e as PointerEvent).pointerType === "mouse") {
                    if (fullPointerSupport) {
                        that.mouseEnterAction((e as MouseEvent), element)
                    }
                }
            }))
            hoverTarget.forEach(element => element.addEventListener(leaveEvent, function (e) {
                if ((e as PointerEvent) === undefined || (e as PointerEvent).pointerType === "mouse") {
                    if (fullPointerSupport) {
                        that.mouseLeaveAction((e as MouseEvent), element)
                    }
                }
            }))

            pointerTarget.forEach(element => element.addEventListener('click', function (e) {
                // noinspection JSUnresolvedReference

                if ((e as PointerEvent) == undefined || (e as PointerEvent).pointerType == "mouse") {
                    that.mouseClickAction((e as MouseEvent), element)
                } else {
                    that.touchAction((e as MouseEvent), element)
                }
                element.removeAttribute('data-pointer-type')
            }))
        } else {
            hoverTarget.forEach(element => element.addEventListener('mouseenter', function (e) {
                that.mouseEnterEventObserved = true
                that.cancelNextTouch = true

                that.mouseEnterAction((e as MouseEvent), element)
            }))

            hoverTarget.forEach(element => element.addEventListener('mouseleave', function (e) {
                that.mouseLeaveAction((e as MouseEvent), element)
            }))

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            window.addEventListener('touchstart', function (_event) {
                if (that.mouseEnterEventObserved) {
                    that.touchEventOrderIncorrect = true
                    that.mouseEnterEventObserved = false
                }

                that.TouchScroll.reset()
            })

            pointerTarget.forEach(element => element.addEventListener('touchend', function (e) {
                (element as HTMLElement).dataset.wasTouch = 'true'
                e.preventDefault()

                if (that.TouchScroll.shouldCancelTouch()) {
                    return
                }

                that.touchAction(e as MouseEvent, element)
            }))

            pointerTarget.forEach(element => element.addEventListener('click', function (e) {
                if ((element as HTMLElement).dataset.wasTouch) {
                    e.preventDefault()
                    return
                }

                that.mouseClickAction(e as MouseEvent, element)
            }))
        }
    }

    mouseEnterAction(_event: MouseEvent, target: Element) {
        if (this.useSmallScreenBehavior()) {
            return // don't do mouse enter functionality on smaller screens
        }

        target.classList.add('menu-active') //show current menu
    }

    /**
     * On large screens, hide menu.
     * On small screens, do nothing.
     */
    mouseLeaveAction(_event: MouseEvent, target: Element) {
        if (this.useSmallScreenBehavior()) {
            return // don't do mouse leave functionality on smaller screens
        }

        target.classList.remove('menu-active')
    }

    /**
     * On large screens, don't interfere so that browser will follow link.
     * On small screens, toggle menu visibility.
     *
     * @param event
     * @param target
     */
    mouseClickAction(event: MouseEvent, target: Element) {
        if (this.useSmallScreenBehavior()) {
            event.preventDefault() //don't follow link
            this.toggleMenuVisibility(target) //instead, toggle visibility
        }
    }

    /**
     * Toggle menu visibility, and prevent event default to avoid
     * undesired, duplicate, synthetic mouse events.
     *
     * @param event
     * @param target
     */
    touchAction(event: MouseEvent, target: Element) {
        this.toggleMenuVisibility(target)
        event.preventDefault()
    }

    preventMenuSpill() {
        const windowWidth = window.innerWidth
        const ulElements = document.querySelectorAll('ul.level0') as NodeListOf<HTMLElement>
        ulElements.forEach((ul) => {
            // Show it long enough to get info, then hide it.
            ul.classList.add('position-test')
            ul.classList.remove('spill')

            // Need the parent element because the ul is position: absolute
            const parentElement = ul.offsetParent as HTMLElement

            const width = ul.offsetWidth
            const offset = parentElement ? parentElement.offsetLeft : ul.offsetLeft

            ul.classList.remove('position-test')

            // Add the spill class if it will spill off the page.
            if ((offset + width + 10) > windowWidth) {
                ul.classList.add('spill')
            }
        })
    }
}

class SkipLinkManager {
    skipContents: NodeListOf<Element> = document.querySelectorAll('.skip-content')
    skipLinks: NodeListOf<Element> = document.querySelectorAll('.skip-link')
    skipLinkClose: NodeListOf<Element> = document.querySelectorAll('#header-cart .skip-link-close')

    constructor() {
        this.addListenersToSkipLinks()
        this.addListenersForSkipLinkClose()
        this.addMediaQueryListener()
    }

    addListenersToSkipLinks() {
        const that = this
        this.skipLinks.forEach(function (skipLink) {
            (skipLink as HTMLElement).addEventListener('click', function (e: MouseEvent) {
                e.preventDefault()

                // Use the data-target-element attribute, if it exists. Fall back to href.
                const target: string | null = this.getAttribute('data-target-element') ? this.getAttribute('data-target-element') : this.getAttribute('href')
                if (!target) {
                    return
                }
                // Get target element
                const elem: HTMLElement | null = document.querySelector(target)

                if (!elem) {
                    return
                }

                // Check if stub is open
                const isSkipContentOpen = elem.classList.contains('skip-active') ? 1 : 0

                // Hide all stubs
                that.skipLinks.forEach(function (link) {
                    link.classList.remove('skip-active')
                })
                that.skipContents.forEach(function (content) {
                    content.classList.remove('skip-active')
                })

                // Toggle stubs
                if (isSkipContentOpen) {
                    this.classList.remove('skip-active')
                    elem.classList.remove('skip-active')
                } else {
                    this.classList.add('skip-active')
                    elem.classList.add('skip-active')
                }

                if (target === '#header-search') {
                    const searchInput = document.getElementById('search')
                    if (searchInput) {
                        searchInput.focus()
                    }
                }
            })
        })
    }

    addListenersForSkipLinkClose() {
        this.skipLinkClose.forEach(function (linkClose) {
            linkClose.addEventListener('click', function (e) {
                e.preventDefault()
                const parent = linkClose.closest('.skip-content')
                if (!parent) {
                    return
                }
                parent.classList.remove('skip-active')
                const link: Element | null = parent.previousElementSibling
                if (!link) {
                    return
                }
                link.classList.remove('skip-active')
            })
        })

    }

    addMediaQueryListener() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const mql = window.matchMedia('screen and (min-width: ' + (bp.medium + 1) + 'px)')

        function removeClasses(classNames: string[]) {
            classNames.forEach(function (className) {
                const elements = document.querySelectorAll('.' + className)
                elements.forEach(function (element) {
                    element.classList.remove(className)
                })
            })
        }

        mql.addEventListener('change', function () {
            removeClasses(['menu-active', 'sub-menu-active', 'skip-active'])
        })

        removeClasses(['menu-active', 'sub-menu-active', 'skip-active'])
    }
}

export {MenuManager, SkipLinkManager}
