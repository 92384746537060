declare global {
    interface HTMLElement {
        toggleSingle(options?: { destruct: boolean }): void;
    }
}

export function toggleContentSetup() {
    HTMLElement.prototype.toggleSingle = function (options) {
        // Merge the options with the default
        const settings = Object.assign({
            destruct: false
        }, options)

        // Assign the anonymous function to a variable
        const toggleActiveDisplay = (e) => {
            e.target.classList.toggle('active')
            e.target.nextElementSibling.classList.toggle('no-display')
        }

        if (!settings.destruct) {
            this.addEventListener('click', toggleActiveDisplay)
            // Hide the content
            if (this.nextElementSibling) {
                this.nextElementSibling.classList.add('no-display')
            }
        } else {
            // Remove event handler so that the toggle link can no longer be used
            this.removeEventListener('click', toggleActiveDisplay)
            // Remove all classes that were added by this plugin
            this.classList.remove('active')
            if (this.nextElementSibling) {
                this.nextElementSibling.classList.remove('no-display')
            }
        }
    }

// UI Pattern - Toggle Content (tabs and accordions in one setup)
    document.querySelectorAll('.toggle-content').forEach(function (wrapper) {
        const hasTabs = wrapper.classList.contains('tabs')
        // const hasAccordion = wrapper.classList.contains('accordion')
        const startOpen = wrapper.classList.contains('open')
        const dl = wrapper.querySelector('dl')
        if (!dl) {
            return
        }
        const dts = dl.querySelectorAll('dt')
        const panes = dl.querySelectorAll('dd')
        const groups = [Array.from(dts), Array.from(panes)]
        let lis: HTMLCollection | null = null

        //Create a ul for tabs if necessary.
        if (hasTabs) {
            const ul = document.createElement('ul')
            ul.classList.add('toggle-tabs')
            Array.from(dts).forEach(function (dt) {
                const li = document.createElement('li')
                li.innerHTML = dt.innerHTML
                ul.appendChild(li)
            })
            wrapper.insertBefore(ul, dl)
            lis = ul.children as HTMLCollection
            groups.push(Array.from(lis) as HTMLElement[])
        }

        //Add "last" classes.
        let i: number
        for (i = 0; i < groups.length; i++) {
            groups[i][groups[i].length - 1].classList.add('last')
        }

        function toggleClasses(clickedItem: any, group: string | any[]) {
            const index = group.indexOf(clickedItem)
            let i: number
            for (i = 0; i < groups.length; i++) {
                groups[i].forEach(function (item) {
                    item.classList.remove('current')
                })
                groups[i][index].classList.add('current')
            }
        }

        //Toggle on tab (dt) click.
        Array.from(dts).forEach(function (dt) {
            dt.addEventListener('click', function () {
                //They clicked the current dt to close it. Restore the wrapper to unclicked state.
                if (this.classList.contains('current') && wrapper.classList.contains('accordion-open')) {
                    wrapper.classList.remove('accordion-open')
                } else {
                    //They're clicking something new. Reflect the explicit user interaction.
                    wrapper.classList.add('accordion-open')
                }
                toggleClasses(this, Array.from(dts))
            })
        })

        //Toggle on tab (li) click.
        if (hasTabs && lis) {
            Array.from(lis).forEach(function (li) {
                li.addEventListener('click', function () {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    toggleClasses(li, Array.from(lis!))
                })
            })
            //Open the first tab.
            // noinspection TypeScriptUnresolvedReference
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            lis[0].click()
        }

        //Open the first accordion if desired.
        if (startOpen) {
            dts[0].click()
        }
    })

}


//** This toggles Elements based on the viewport width */
class ElementToggler {
    private mediaQuery: MediaQueryList
    private toggleElements: NodeListOf<HTMLElement>
    // noinspection TypeScriptFieldCanBeMadeReadonly
    private breakpoint = 768

    constructor(breakpoint = 768) {
        toggleContentSetup()
        this.breakpoint = breakpoint
        this.toggleElements = document.querySelectorAll(
            '.col-left-first .block:not(.block-layered-nav) .block-title, ' +
            '.col-left-first .block-layered-nav .block-subtitle--filter, ' +
            '.sidebar:not(.col-left-first) .block .block-title'
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.mediaQuery = window.matchMedia('(max-width: ' + this.breakpoint + 'px)')
        this.mediaQuery.addEventListener('change', this.update.bind(this))
        this.update()
    }

    match() {
        this.toggleElements.forEach(element => element.toggleSingle())
    }

    unmatch() {
        this.toggleElements.forEach(element => element.toggleSingle({destruct: true}))
    }

    update() {
        if (this.mediaQuery.matches) {
            this.match()
        } else {
            this.unmatch()
        }
    }
}

export {ElementToggler}
